import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Posts from "../components/Posts"
import SEO from "../components/seo"
import "../styles/main.css"

const ResourcesPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <SEO title="Resources" />
      <div className="resource__container">
        <h1 className="post__heading">
          Here are some resources to learn web development
        </h1>
        <Posts posts={posts} key={posts.id} />
      </div>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/resources/" } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        frontmatter {
          title
          path
          slug
          alt
          date(formatString: "MMMM Do, YYYY")
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
